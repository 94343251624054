.lifestyle {
  background-color: #ffffff;
  width: 100%;
}

.lifestyle-intro {
  margin-left: 20px;
  margin-right: 20px;
  font-size: 18px;
  color: #141414;
  font-family: Roboto-Regular;
  text-align: center;
  line-height: 1.33;
  font-weight: 400;
  padding-top: 30px;
}

@media (min-width: 1024px) {
  .lifestyle-intro {
    margin-left: 290px;
    margin-right: 290px;
  }
}

.lifestyle-section {
  margin-top: 30px;
  background-color: #d8d8d84d;
}

.lifestyle-icons-intro {
  padding-top: 18px;
  margin-left: 20px;
  margin-right: 20px;
}

.lifestyle-icons-intro span {
  font-size: 14px;
  text-align: center;
  color: #84919c;
  line-height: 1.43;
  font-weight: 900;
}

.lifestyle-icons {
  min-height: 200px;
}

.lifestyle-icon {
  margin-left: 20px;
  float: left;
  margin-top: 20px;
}

.lifestyle-icon-selected {
  border: 2px solid #5b89b7;
  border-radius: 10px;
  -webkit-box-shadow: inset 0px 0px 0px 3px #5b89b7;
  -moz-box-shadow: inset 0px 0px 0px 3px #5b89b7;
  box-shadow: inset 0px 0px 0px 3px #5b89b7;
}

.lifestyle-icon-unselected {
  opacity: 0.4;
}

.lifestyle-text-container {
  margin-top: 20px;
}

.lifestyle-text {
  max-width: 480px;
  font-family: "Roboto";
  font-size: 14px;
  line-height: 1.38;
  float: left;
}

.lifestyle-explanation {
  margin-left: 20px;
}

.lifestyle-explanation-type {
  font-size: 14px;
  color: #8b96a1;
}

.lifestyle-explanation-name {
  font-size: 14px;
  line-height: 1.38;
  color: #141414;
}

.lifestyle-explanation-desc {
  margin-top: 6px;
}

.lifestyle-bg-image {
  float: left;
}

@media (max-width: 1024px) {
  .lifestyle-bg-image {
    display: none;
  }
}

.lifestyle-images-text {
  text-align: center;
  background-color: #1b2029;
  border-radius: 6px;
  margin-top: -40px;
  color: #ffffff;
  margin-left: 70px;
}

@media only screen and (max-width:767px) {
  .lifestyle-section {
    margin-bottom: 100px;
  }  
}
