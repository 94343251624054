.MuiSvgIcon-root {
  width: 10px !important;
  height: 10px !important;
  font-size: 10px !important;
}

.makeStyles-root-61 {
  width: 10px !important;
  height: 10px !important;
  font-size: 10px !important;
}

.makeStyles-root-60 {
  width: 10px !important;
  height: 10px !important;
  font-size: 10px !important;
}
