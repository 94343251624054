.input-element {
  box-shadow: 1px 2px 4px 0 rgba(20, 20, 20, 0.2);
  border: solid 1px #d8d8d8;
  background-color: #ffffff;
  height: 40px;
  width: 100%;
  box-sizing: border-box;
  padding-left: 10px;
}

.label-text {
  font-family: Roboto;
  font-size: 14px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #141414;
}  

.label-red {
  color: #b43535 !important;
}

.buttons-bottom {
  font-family: 'Roboto-Regular' !important;
  font-size: 14px;
  height: 44px;
  width: 100%;
}

.modal-button-left {
  color: #004d87 !important;
  text-transform: none !important;
  font-weight: bold !important;
}

.button-right-normal {
  border-radius: 6px !important;
  color: white !important;
  height: 44px;
  text-transform: none !important;
  background-color: #1b2029 !important;
}

.button-right-error {
  background-color: #b43535 !important;
}

.checkbox {
  width: 40px;
  height: 40px;
  border-radius: 6px;
  box-shadow: 1px 2px 4px 0 rgba(20, 20, 20, 0.2);
  border: solid 1px #141414;
  background-color: #ffffff;
}

.checkbox-selected {
  width: 40px;
  height: 40px;
  border-radius: 6px;
  box-shadow: 1px 2px 4px 0 rgba(20, 20, 20, 0.2);
  border: solid 1px #141414;
  background-color: #141414 !important;
}

.stay-updated-err {
  color: #b43535;
}
