.benefit-result {
  margin-top: 6px;
  display: flex;
  width: 260px;
}

.benefit-result-text {
  font-family: Roboto;
  font-size: 11px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #141414;
}

.benefit-result-text-left {
  width: 129px;
  font-weight: 500;
  text-align: left;
}

.benefit-result-text-right {
  width: 61px;
  text-align: right;
  font-weight: 900;
}

.inactive {
  color: #8797a3 !important;
}

.bg-custom {
  background-color: #8797a3 !important;
}
