.profile-item-image {
  float: left;
  text-align: center;
  width: 140px;
  height: 140px;
  border-radius: 6px;
  -webkit-box-shadow: 0px 6px 5px 0px rgba(20, 20, 20, 0.2);
  -moz-box-shadow: 0px 6px 5px 0px rgba(20, 20, 20, 0.2);
  box-shadow: 0px 6px 5px 0px rgba(20, 20, 20, 0.2);
}

.profile-item-text {
  display: inline-block;
  text-align: center;
  background-color: #1b2029;
  border-radius: 6px;
  margin-top: 90px;
}

.profile-item-text p {
  font-size: 14;
  color: white;
  padding: 10px 6px;
}

.image-1-dan {
  background-image: url('../../../images/carnivore-2.png');
}

.image-2-dan {
  background-image: url('../../../images/stressed.png');
}

.image-3-dan {
  background-image: url('../../../images/sedentary.png');
}

.image-4-dan {
  background-image: url('../../../images/suburban.png');
}

.image-1-sophie {
  background-image: url('../../../images/vegetarian.png');
}

.image-2-sophie {
  background-image: url('../../../images/surfer.png');
}

.image-3-sophie {
  background-image: url('../../../images/active.png');
}

.image-4-sophie {
  background-image: url('../../../images/urban.png');
}
