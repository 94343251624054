/* --- MODAL --- */
.pure-modal {
  padding-top: 5px;
}

/* --- SWIPER STYLES --- */
.swiper-container {
  width: 100%;
  height: 100%;
}
.swiper-slide {
  background: #fff;
  border-radius: 10px;
  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
  width: 80%;
}

.swiper-pagination-bullet {
  opacity: 1;
  background-color: #BEBEBE;
  width: 14px;
  height: 14px;
}

.swiper-pagination-bullet-active {
  background-color: gray;
  opacity: 1;
}

.profile-container .panel-heading {
  margin-bottom: 60px;
  margin-right: 65px;
}

.profile-container .panel-heading .close {
  width: 44px;
  height: 44px;
  background-color: rgb(255, 255, 255);
  opacity: 1;
  border-radius: 50px;
  font-size: 50px;
  text-align: center;
  padding-top: 6px;
}

.swiper-button-next,
.swiper-button-prev {
  width: 54px;
  height: 54px;
  background-color: #BEBEBE;
  border-radius: 50%;
  margin-left: 45px;
  margin-right: 45px;
}

.swiper-button-next {
  background-image: url('../../../images/white-arrow-next.png');
  background-size: 30px;
}

.swiper-button-prev {
  background-image: url('../../../images/white-arrow-prev.png');
  background-size: 30px;
}


@media only screen and (max-width:767px) {
  .swiper-slide {
    width: 100% !important;
  }

  .swiper-button-next,
  .swiper-button-prev {
    width: 30px;
    height: 30px;
    margin-left: 0px;
    margin-right: 0px;
  }

  .swiper-button-next {
    background-size: 18px;
  }
  
  .swiper-button-prev {
    background-size: 18px;
  }

  .profile-container .panel-heading .close{
    left: 100%;
    top: 0;
  }
}

