.profile-card {
  width: 300px;
  height: 300px;
  border-radius: 6px;
  box-shadow: 1px 2px 4px 0 var(--black-60), 1px 2px 4px 0 var(--black-60);
  color: white;
  margin: 10px;
}

.profile-card-custom {
  width: 300px;
  height: 240px;
}

.profile-card-image {
  width: 300px;
  height: 300px;
}

.profile-card img {
  border-radius: 6px;
}

.profile-image-dan {
  background-image: url("../../../images/dan-profile2.png");
}

.profile-image-sophie {
  background-image: url("../../../images/sophie-profile2.png");
}

.profile-card-image-text {
  font-size: 18px;
  font-weight: normal;
  line-height: 1.33;
  padding: 33px 24px 0px 24px;
  text-align: left;
  opacity: 0.3;
}

.profile-card-description {
  text-align: left;
  font-weight: bold;
  padding-top: 18px;
}

.profile-card-description-img {
  margin-top: 241px;
}

.profile-card-description-custom {
  margin-top: 70px;
  opacity: 0.3;
}

.profile-description-text,
.profile-card-custom-text,
.profile-location-content {
  float: left;
}

.profile-card-custom-text {
  padding-left: 20px;
}

.profile-card-mt {
  margin-top: 225px;
  background-color: #141414;
  height: 60px;
  width: 300px;
  padding-left: 20px;
  padding-top: 18px;
}

.profile-location {
  margin-left: 65px;
  float: left;
}

.profile-location-icon {
  width: 12px;
  height: 16px;
  margin-right: 5px;
  background-size: 11px;
  background-image: url("../../../images/map_pin_white.png");
  float: left;
}

.profile-location-text {
  font-size: 14px;
  font-weight: normal;
  float: left;
}

.profile-location button {
  width: 140px;
  height: 44px;
  border-radius: 6px;
  font-size: 16px;
  background-color: #004d83;
  border: #004d83;
  color: #ffffff;
}

.profile-location-button {
  margin-top: -20px;
}

.custom-profile {
  background-color: #141414;
}

.profile-card-commingsoon {
  margin-top: -174px;
}

.profile-card-commingsoon span {
  font-size: 24px;
  font-weight: 900;
}
