/* get the required local files */
@font-face {
  font-family: "Roboto-Regular";
  src: url("./fonts/Roboto/Roboto-Regular.ttf");
  /*src: url('roboto.eot'); */ /* IE9 Compat Modes */
  /*src: url('roboto.eot?#iefix') format('embedded-opentype'), */ /* IE6-IE8 */
  /*url('roboto.woff') format('woff'), */ /* Modern Browsers */
  /*url('roboto.ttf')  format('truetype'), */ /* Safari, Android, iOS */
  /*url('roboto.svg#svgFontName') format('svg');*/ /* Legacy iOS */
}

/* get the required local files */
@font-face {
  font-family: "RobotoBlack";
  src: url("./fonts/Roboto/Roboto-Black.ttf");
  /*src: url('roboto.eot'); */ /* IE9 Compat Modes */
  /*src: url('roboto.eot?#iefix') format('embedded-opentype'), */ /* IE6-IE8 */
  /*url('roboto.woff') format('woff'), */ /* Modern Browsers */
  /*url('roboto.ttf')  format('truetype'), */ /* Safari, Android, iOS */
  /*url('roboto.svg#svgFontName') format('svg');*/ /* Legacy iOS */
}
html,
body,
div,
span,
applet,
object,
iframe,
h1,
h2,
h3,
h4,
h5,
h6,
p,
blockquote,
pre,
a,
abbr,
acronym,
address,
big,
cite,
code,
del,
dfn,
em,
img,
ins,
kbd,
q,
s,
samp,
small,
strike,
strong,
sub,
sup,
tt,
var,
b,
u,
i,
center,
dl,
dt,
dd,
ol,
ul,
li,
fieldset,
form,
label,
legend,
table,
caption,
tbody,
tfoot,
thead,
tr,
th,
td,
article,
aside,
canvas,
details,
embed,
figure,
figcaption,
footer,
header,
hgroup,
menu,
nav,
output,
ruby,
section,
summary,
time,
mark,
audio,
video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  font: inherit;
  vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
menu,
nav,
section {
  display: block;
}
body {
  line-height: 1;
  padding-top: 84px;
}
ol,
ul {
  list-style: none;
}
blockquote,
q {
  quotes: none;
}
blockquote:before,
blockquote:after,
q:before,
q:after {
  content: "";
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
}

body {
  margin: 0;
  font-family: "Roboto-Regular", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #eef2f5;
}

h1,
h2 {
  margin: 0;
  padding: 0;
}

.clear {
  clear: both;
}

.bold {
  font-weight: 900;
}

.center {
  text-align: center;
}

.inline-block {
  display: inline-block;
}

.float-left {
  float: left;
}

.float-right {
  float: right;
}

.white-bg {
  background-color: #ffffff;
}

.fixed-top {
  background-color: #eef2f5;
}
