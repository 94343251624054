.checkout-cart-header {
  height: 60px;
  background-color: #f5f5f5;
  box-shadow: 0px 4px 5px -1px rgba(0, 0, 0, 0.5);
}

.checkout-cart-header-text {
  font-size: 14px;
  font-weight: bold;
}

.checkout-points-image {
  width: 12px;
  height: 14px;
  background-image: url('../../../images/dna.svg');
  margin-top: 18px;
}

.checkout-button button {
  width: 100%;
  height: 44px;
  border-radius: 6px;
  background-color: #007ecc;
  color: white;
  border: none;
  margin-top: 10px;
  font-size: 14px;
}

.total-points-text{
  color: black;
  font-size: 30px;
}

.total-points-label{
  font-weight: 900;
  color: black;
  font-size: 12px;
}