.footer {
  height: 60px;
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100%;
  box-shadow: 1px 2px 4px 0 #141414;
  background-color: rgba(245, 245, 245, 0.8);
}

.footer-div {
  display: flex;
  line-height: 60px;
}
.buttons-center {
  width: 326px;
  margin-left: auto;
}

.button-center-span {
  width: 176px;
  height: 20px;
  font-family: Roboto;
  font-size: 10px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 2;
  letter-spacing: normal;
  color: #141414;
  margin-right: 10px;
}

.button-center {
  width: 139px;
  height: 44px;
  object-fit: contain;
  border-radius: 6px !important;
  background-color: #1b2029 !important;
  text-transform: none !important;
  color: #ffffff !important;
  font-family: 'Roboto-Regular' !important;
  font-size: 14px !important;
  font-weight: 500 !important;
  font-stretch: normal !important;
  font-style: normal !important;
  line-height: 1.43 !important;
  letter-spacing: normal !important;
  text-align: center !important;
  outline: none !important;
}

.buttons-right {
  float: right !important;
  font-family: 'Roboto-Regular' !important;
  font-size: 14px;
  height: 44px;
  margin-right: 10px;
  margin-left: auto;
}

.button-right {
  background-color: #047700 !important;
  border-radius: 6px !important;
  color: white !important;
  width: 242px;
  height: 44px;
  text-transform: none !important;
}

.button-left {
  color: #1b2029 !important;
  text-transform: none !important;
  margin-right: 20px !important;
}
