.carusel-item-card {
  width: 780px;
  height: 600px;
  color: #141414;
  font-family: 'Roboto';
}

.carusel-item-container {
  width: 100%;
  height: 100%;
  padding: 46px 60px;
  /* Style for image passed trough props */
  background-size: 400px;
  background-repeat: no-repeat;
  background-position-x: 90%;
  background-position-y: 16px;
}

.carusel-title {
  font-size: 48px;
  font-weight: bold;
  margin-bottom: 24px;
}

.map-pin {
  float: left;
  margin-right: 13px;
}

.carousel-birthday {
  background-image: url('../../../images/birthday-cake-solid.png');
  width: 18px;
  height: 21px;
  margin-top: -4px;
}

.carousel-map {
  background-image: url('../../../images/map-marker-alt.png');
  width: 18px;
  height: 24px;
  margin-top: -4px;
}

.carousel-work {
  background-image: url('../../../images/shape.png');
  width: 21px;
  height: 18px;
  margin-top: -3px;
}

.carousel-info-row {
  margin-top: 13px;
}

.carusel-info p {
  font-size: 16px;
  float: left;
}

.carusel-item-images {
  padding: 82px 0 0 0;
}

.carousel-image {
  float: left;
  text-align: center;
  width: 140px;
  height: 140px;
  border-radius: 6px;
  margin-right: 20px;
  -webkit-box-shadow: 0px 6px 5px 0px rgba(20, 20, 20, 0.2);
  -moz-box-shadow: 0px 6px 5px 0px rgba(20, 20, 20, 0.2);
  box-shadow: 0px 6px 5px 0px rgba(20, 20, 20, 0.2);
}

.image-1-dan {
  background-image: url('../../../images/carnivore-2.png');
}

.image-2-dan {
  background-image: url('../../../images/stressed.png');
}

.image-3-dan {
  background-image: url('../../../images/sedentary.png');
}

.image-4-dan {
  background-image: url('../../../images/suburban.png');
}

.image-1-sophie {
  background-image: url('../../../images/vegetarian.png');
}

.image-2-sophie {
  background-image: url('../../../images/surfer.png');
}

.image-3-sophie {
  background-image: url('../../../images/active.png');
}

.image-4-sophie {
  background-image: url('../../../images/urban.png');
}

.carusel-images-text {
  display: inline-block;
  text-align: center;
  background-color: #1b2029;
  border-radius: 6px;
  margin-top: 90px;
}

.carusel-images-text p {
  font-size: 14;
  color: white;
  padding: 10px 6px;
}

.buttons-title {
  font-size: 24px;
  font-weight: bold;
  text-align: center;
  color: #333333;
  padding: 51px 0 24px;
}

.buttons-container {
  text-align: center;
  padding-bottom: 50px;
}

.buttons-container button {
  width: 140px;
  height: 44px;
  font-size: 14px;
  color: white;
  background-color: #047700;
  border-radius: 10px;
  margin: 0 10px;
  border: none;
}

.buttons-container :disabled {
  opacity: 40%;
}

@media only screen and (max-width:767px) {
  .carusel-item-container {    
    padding: 20px;
    height: 500px;
    background-position: 150% 20%;
    background-repeat: no-repeat;
    background-size: 70%;
    position: relative;
  }

  .carusel-title{
    margin-top: 4%;
    margin-left: 8%;
    font-size: 34px;
  }

  .carusel-info{
    margin-left: 8%;
  }

  .carusel-info p{
    font-size: 14px;
    font-weight: 500;
  }

  .carusel-item-images {
    width: 90%;
    margin: 0 auto;
    padding-top: 10px;
  }

  .carousel-image {
    width: 45%;
    margin: 2.5%;
    height: 100px;
  }

  .carusel-images-text {
    margin-top: 64px;
    font-size: 12px;
  }

  .carusel-item-card{
    height: 700px;
  }

  .buttons-title {
    font-size: 18px;
    padding: 26px 0 10px;
  }

  .buttons-container button {
    margin-top: 5px;
    width: 80% !important;
    margin-left: 10%;
    height: 40px !important;
  }
}