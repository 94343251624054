.center {
  text-align: center;
}

.inline-block {
  display: inline-block;
}

.container {
  background-color: #eef2f5;
}

.dna-title-text {
  font-size: 24px;
  margin-bottom: 30px;
  margin-top: 36px;
  max-width: 780px;
  margin-left: 20px;
  margin-right: 20px;
}

@media (min-width: 1024px) {
  .dna-title-text {
    margin-left: 180px;
    margin-right: 180px;
  }
}

.showOptions {
  margin-bottom: 18px;
}

.spacer {
  float: left;
}

.dna-components-container {
  padding-bottom: 60px;
  margin-left: 20px;
  margin-right: 20px;
}

@media only screen and (max-width:767px) {
  .dna-title-text{
    margin-top: 80px;
  }
}
