.pure-modal {
  width: 100% !important;
  height: 100% !important;
}

.pure-modal .panel-default {
  margin-top: 25px;
  margin-bottom: 25px;
}

.pure-modal .panel-heading {
  background-color: white !important;
  filter: alpha(opacity=50); /* For IE8 and earlier */
  color: #0a2764;
  border-radius: 15px 15px 0 0;
}

.panel-heading h3 {
  padding-top: 20px !important;
  padding-bottom: 20px !important;
  font-size: 1.25rem !important;
  text-align: center;
}

.pure-modal .panel-footer {
  border-radius: 0 0 15px 15px !important;
}

.pure-modal .panel-body {
  color: #5c5c5c !important;
  /*Set to "inherit" to remove default color*/
  background-color: inherit;
  padding: 0px !important;
}
