.margin-auto {
  margin: auto;
}

.cart-header-container {
  width: 100%;
  color: #141414;
  background-color: #f5f5f5;
  -webkit-box-shadow: 0px 4px 5px -1px rgba(0, 0, 0, 0.5);
  -moz-box-shadow: 0px 4px 5px -1px rgba(0, 0, 0, 0.5);
  box-shadow: 0px 4px 5px -1px rgba(0, 0, 0, 0.5);
  margin-bottom: 4px;
  padding-left: 1%;
  padding-right: 1%;
  padding-top: 0.25%;
  padding-bottom: 0.25%;
}

.cart-header-image {
  height: 18px;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: left bottom;
}

.cart-header-text {
  font-size: 18px;
  font-weight: bold;
}

.option-icon-image {
  border: 3px solid white;
  border-radius: 25px;
}

@media only screen and (max-width: 767px) {
  .cart-header-container {
    padding-top: 1.5%;
    padding-bottom: 1.5%;
  }
}