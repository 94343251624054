.center {
  text-align: center;
}

.cart-item-container {
  margin: 5%;
  width: 95%;
  background-color: white;
  float: left;
}

/* POINTS */

.cart-item-points {
  width: 42px;
  height: 42px;
  border-radius: 50%;
  background-color: #141414;
}

.points-icon {
  width: 12px;
  height: 14px;
}

.points-ammount {
  font-size: 12px;
  color: white;
}

.points-ammount img {
  margin-top: 2px;
}

/* ITEM */

.item-image {
  width: 100%;
  background-size: 100%;
  background-repeat: no-repeat;
  background-position: center top;
}

.cart-item button {
  display: inline-block;
  width: 140px;
  height: 24px;
  border-radius: 6px;
  background-color: #8c97a1;
  margin-top: 129px;
  border: none;
  outline: none !important;
}

/* DESCRIPTION */

.cart-item-description {
  padding: 0 10px;
  margin-top: 30px;
}

.description-title {
  font-family: Roboto;
  font-size: 12px;
  font-weight: bold;
  text-align: center;
  color: #141414;
  margin-top: 12px;
  margin-bottom: 5px;
}

.description-text {
  font-family: Roboto;
  font-size: 12px;
  text-align: center;
  color: #141414;
}

/* ADVICE */

.advice-title {
  font-family: Roboto;
  font-size: 10px;
  text-align: center;
  color: #8b96a1;
  margin-top: 12px;
  margin-bottom: 5px;
}

.advice-decription {
  font-family: Roboto;
  font-size: 12px;
  text-align: center;
  color: #141414;
}
