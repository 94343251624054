.col-2 {
  padding: 0px !important;
}

.col-4 {
  padding: 0px !important;
}

.dna-container {
  margin-bottom: 17px;
}

.row {
  margin: 0px !important;
}

.center {
  text-align: center;
}

.titleText {
  font-size: 24px;
  padding: 0 50px;
  margin-bottom: 30px;
}

.showOptions {
  margin-bottom: 18px;
}

.optionsButton {
  width: auto;
  height: auto;
  float: left;
  font-size: 14px;
  font-weight: bold;
  color: #004d83;
  margin-left: 6px;
  margin-right: 6px;
}

.numberOfReports {
  width: auto;
  height: auto;
  float: left;
  font-size: 14px;
  font-weight: bold;
  color: #141414;
  margin-left: 6px;
  margin-right: 6px;
  font-weight: 800;
  font-family: Roboto;
}

.spacer {
  float: left;
}

.dna-component {
  max-width: 780px;
  height: 107px;
  background-color: white;
  border-radius: 6px 6px 0 0;
  border-color: #c1c1c1;
  border-style: solid;
  border-width: 1.5px;
  margin-bottom: 17px;
}

.section-description-image {
  width: 50px;
  height: 50px;
  float: left;
  background-image: url("../../../../images/snowflake.png");
  margin: 25px 20px;
}

.section-description .title {
  color: #141414;
  margin: 30px 0 5px 0;
  font-family: Roboto;
  font-size: 22px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
}

.section-description .button {
  font-size: 14px;
  font-weight: bold;
  color: #004d83;
}

.info-container {
  text-align: center;
  border-right: 1px solid;
}

.info-container .score {
  font-size: 24px;
  font-weight: bold;
  color: #84919c;
  margin-top: 30px;
  margin-bottom: 5px;
}

.info-container .text {
  font-size: 14px;
  font-weight: bold;
  color: #141414;
}

.dna-component button {
  width: 216px;
  height: 40px;
  border-radius: 6px;
  margin-top: 34px;
  border: none;
  margin-left: 21px;
  color: #ffffff;
  font-size: 16px;
  font-family: "Roboto" !important;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
}

.dna-component-risky {
  background-color: #b43535;
}

.dna-component-moderate {
  background-color: #e68000;
}

.dna-component-normal {
  background-color: #10a000;
}

.report {
  max-width: 780px;
  border-radius: 0 0 6px 6px;
  border-color: #c1c1c1;
  border-style: solid;
  border-width: 1.5px;
  background-color: #f5f5f5;
}

.report .text {
  font-family: Roboto;
  font-size: 16px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.38;
  letter-spacing: normal;
  color: #141414;
  padding: 20px 24px 28px 20px;
  text-align: left;
}

.title-container {
  text-align: left;
  margin-left: 20px;
}

.percentage-sm,
.population-sm {
  display: none;
}

@media (max-width: 1024px) {
  .dna-info {
    display: none;
  }

  .section-description .title {
    font-size: 16px;
  }

  .dna-component button {
    width: 166px;
    height: 32px;
    font-size: 14px;
    margin-left: -24px;
  }

  .percentage-sm,
  .population-sm {
    display: inherit;
  }
}
