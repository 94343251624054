.headerRow {
  padding-left: 24px;
  padding-right: 30px;
  display: flex;
  justify-content: space-between;
}

.header-image {
  width: 26px;
  height: 29px;
  margin-right: 10px;
}

.headerTitle {
  padding-left: 0px !important;
  padding-right: 0px !important;
  width: 392px !important;
}

.headerTitleText {
  font-size: 14px;
  padding-top: 5px;
  color: #141414;
  font-family: "Roboto";
}

.headerSubtitleText {
  font-size: 10px;
  color: #8b98a3;
  font-family: "Roboto";
}

.headerNavigation {
  padding-left: 0px !important;
}

.header-navigation-left {
  width: 360px;
  text-align: right;
  font-size: 12px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #1a202a;
}

.stepperCard {
  width: 465px;
  height: 54px;
  background-color: #f9f9fa;
  border-radius: 0 0 5px 5px;
  padding: 10px;
  -webkit-box-shadow: 0px 6px 5px 0px rgba(20, 20, 20, 0.2);
  -moz-box-shadow: 0px 6px 5px 0px rgba(20, 20, 20, 0.2);
  box-shadow: 0px 6px 5px 0px rgba(20, 20, 20, 0.2);
}

.stepperTitle {
  width: 140px;
  height: 30px;
  background-color: rgb(27, 32, 41);
  font-size: 14px;
  color: white;
  border-radius: 0 0 5px 5px;
}

.center {
  text-align: center;
}

.v-center {
  vertical-align: middle;
}

.inline-block {
  display: inline-block;
}

.pceheader .MuiPaper-root {
  background-color: #f9f9fa !important;
}

.MuiStepLabel-label.MuiStepLabel-alternativeLabel {
  margin-top: -27px !important;
  font-size: 12px;
}

.MuiStepLabel-iconContainer.MuiStepLabel-alternativeLabel {
  padding-top: 20px;
}

.MuiStepConnector-lineHorizontal {
  margin-top: 13px;
}

@media only screen and (max-width: 767px) {
  .stepperCard {
    width: 95%;
    margin-left: 2.5%;
  }

  .MuiStep-alternativeLabel {
    flex: auto !important;
  }

  .MuiStepConnector-alternativeLabel {
    left: calc(-50% + 10px) !important;
    right: calc(-15% + 40px) !important;
  }

  .lifestyle {
    margin-top: 80px;
  }
}
