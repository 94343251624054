.overlay-modal-close-button {
    position: absolute;
    top: 0px;
    right: 0px;    
    border-radius: 50px;;
    margin-right: 5px;
    margin-top: 5px;
}

.MuiDialog-paperWidthSm {
    max-width: 780px !important;
}

.main-title {
  font-family: Roboto;
  font-size: 24px;
}

.modal-text-body-big {
  font-size: 24px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: center;
}

.line-small {
  width: 55%;
  height: 1px;
  margin: 0 auto;
  border: solid 1px #d8d8d8;
}

.modal-text-body-small {
  font-family: Roboto;
  font-size: 14px;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.43;
  letter-spacing: normal;
  color: #141414;
}

.line-big {
  color: #d8d8d8;
  box-shadow: 1px 2px 4px grey;
  width: 55%
}

.modal-button {
  width: 100%;
  max-width: 220px;
  height: 44px;
  border-radius: 6px;
  background-color: #1b2029;
  color: #ffffff;
}

@media only screen and (max-width: 767px) {
    .MuiDialog-paper {
        margin: 5px !important;
    }

    .MuiBackdrop-root {
        background-color: dimgray !important;
    }
}