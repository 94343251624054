.retail-experience-cart-container {
  background-color: white;
  width: auto;
  margin-top: 98px;
}

.back-option {
  height: 50px;
  padding-left: 30px;
  border-bottom: 1px solid rgb(20, 20, 20, 0.2);
  background-color: #f5f5f5;
}

.back-image {
  width: 18px;
  height: 9px;
  background-image: url("../../../images/blue_arrow.svg");
  background-size: 18px;
}

.back-text {
  font-family: Roboto;
  font-size: 12px;
  color: #007ecc;
  padding-left: 10px;
}

.v-center {
  margin-top: auto;
  margin-bottom: auto;
}

.flex {
  display: flex;
}

.modal-body-background {
  background-color: white;
  padding: 3%;
}

.panel-footer {
  background-color: white !important;
}

.retail-experience-modal .pure-modal .panel-default {
  margin-top: 200px !important;
  max-width: 40%;
  margin-left: 60%;
  margin-right: 0.25%;
  margin-bottom: 50%;
}

.modal-header-text {
  color: black;
  font-size: 17px;
  font-weight: bold;
}

.desktop-cart-container {
  box-shadow: 0px 4px 5px -1px rgba(0, 0, 0, 0.5);
}

.options-brand select {
  height: 30px;
  width: 100%;
}

.filters-label-text {
  color: black;
  font-size: 16px;
  font-weight: bold;
}

.price button {
  height: 30px;
  width: 45px;
  margin-right: 8px;
  font-size: 12;
  border-radius: 6px;
  background-color: #f5f5f5;
  outline: none !important;
}

.unselected {
  border: solid 1px #8b96a1;
}

.selected {
  border: 2px solid #004d83;
}

@media only screen and (max-width: 767px) {
  .retail-experience-cart-container {
    margin-top: 130px;
  }

  .retail-experience-modal .pure-modal .panel-default {
    max-width: 100%;
    margin-top: 60% !important;
    height: 64%;
    margin-left: 2.5%;
    margin-right: 2.5%;
  }
}
