.checkout-item-container {
  height: auto;
  border-bottom: 1px solid rgb(20, 20, 20, 0.2);
  margin-bottom: 5px !important;
}

.checkout-item-image {
  width: 75px;
  height: 75px;
  background-size: 75px;
  max-height: 75px;
  z-index: -1;
  position: absolute;
}

.checkout-items-amount {
  width: 24px;
  height: 24px;
  font-size: 12px;
  font-weight: bold;
  background-color: gray;
  border-radius: 50%;
  margin-top: -10px;
  margin-left: 24px;
  color: white;
  text-align: center;
  line-height: 24px;
  z-index: 100;
  position: relative;
}

.checkout-item-description {
  font-family: Roboto;
  color: black;
  font-size: 12px;
  line-height: 1.5;
}

.checkout-item-options {
  font-size: 12px;
}

.item-option {
  padding: 0 5px;
  color: #007ecc;
}

.border-right {
  border-right: 1px solid rgb(0, 0, 0, 1);
}

.checkout-points-amount {
  font-size: 16px;
  margin-top: 18px;
  margin-left: 4px;
  font-weight: bold;
  color: black;
}
