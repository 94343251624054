.button-right-mobile {
  width: 80px;
}

.button-left-mobile {
  width: 80px;
}

.button-dark {
  background-color: #1b2029 !important;
  color: #ffffff !important;
  margin-left: 5px !important;
  height: 44px;
  line-height: 1.75;
  font-size: 12px !important;
  padding: 1px !important;
}