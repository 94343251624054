body .benefits {
  background-color: #ffffff !important;
}

.upper-div {
  height: 148px;
  position: relative;
  top: 24px;
}

.logo {
  width: 120px;
  height: 18px;
  margin: 0 auto;
}

.logo-div {
  width: 100px;
  height: 24px;
  margin: 0 auto;
}

.total-score-div {
  width: 182px;
  height: 42px;
  display: flex;
  margin: 0 auto;
  margin-top: 18px;
}

.total-score {
  height: 42px;
  font-family: Roboto;
  font-size: 42px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1;
  letter-spacing: normal;
  text-align: center;
}

.total-score-logo {
  width: 36px;
  height: 36px;
  margin-right: 10px;
  margin-top: 2px;
}

.total-score-right {
  color: #8797a3;
}

.text-div {
  margin: 0 auto;
  width: 250px;
  height: 20px;
  margin-top: 5px;
  text-align: center;
}

.text-span {
  font-family: RobotoBlack;
  font-size: 20px;
  font-weight: 500;
  font-stretch: normal;
  font-style: bold;
  line-height: 1.2;
  letter-spacing: normal;
  text-align: center;
  color: #141414;
}

.text-link {
  font-family: Roboto;
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.5;
  letter-spacing: normal;
  color: #007ecc;
}

.bottom-div {
  background-color: #edf2f5;
  height: 468px;
  border-top: solid 1px #d8d8d8;
  position: relative;
  top: 24px;
}

.cards {
  border-radius: 6px;
  box-shadow: 1px 2px 4px 0 rgba(20, 20, 20, 0.2);
  background-color: #ffffff !important;
}

.center-card {
  width: 300px;
  height: 244px;
  position: relative;
  bottom: 30px;
}

.center-card-div {
  width: 300px;
  height: 74px;
  border-radius: 6px;
  background-color: #f5f5f5;
}

.person-image {
  width: 82px;
  height: 78px;
  margin-left: 111px;
  margin-top: 8px;
}

.progress-bar-active {
  width: 90px;
  height: 12px;
  border-radius: 6px;
  border: solid 1px #8797a3;
  margin-top: 2px;
}

.progress-bar {
  border-radius: 6px;
}

.benefit-results {
  margin-left: 20px;
  margin-right: 20px;
  margin-top: 21px;
  width: 260px;
  text-align: center;
}

.benefit-results-header {
  display: inline-block;
  height: 21px;
  font-family: Roboto;
  font-size: 18px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #1a1f29;
}

.benefit-results-content {
  margin-top: 19px;
}

.benefit-results-total-score {
  margin-top: 13px;
}

.benefit-results-total-score-text {
  height: 20px;
  font-family: Roboto;
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.67;
  letter-spacing: normal;
  margin-right: 51px;
  color: #141414;
}

.benefit-results-total-score-points {
  width: 52px;
  height: 20px;
  font-family: Roboto;
  font-size: 16px;
  font-weight: 900;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.25;
  letter-spacing: normal;
  text-align: right;
  margin-left: 2px;
}

.benefit-results-bottom-navigation {
  display: flex;
  margin-left: 169px;
}

.benefit-results-bottom-navigation-element {
  width: 300px;
  height: 14px;
  text-align: center;
  margin-right: 21px;
}

.benefit-results-bottom-navigation-text {
  font-size: 12px !important;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #007ecc;
  margin-right: 10px;
  margin-left: 10px;
}

.left-card {
  width: 300px;
  height: 250px;
  margin-top: 54px;
}

.right-card {
  width: 300px;
  height: 250px;
  margin-top: 54px;
}

.cards-div {
  display: flex;
  flex-flow: row wrap;
}

.inactive {
  color: #8797a3 !important;
}

.result-margin {
  margin-top: 28px;
}

.benefits-c {
  order: 2;
}

.benefits-l {
  order: 1;
}

.benefits-r {
  order: 3;
}

.benefits-product-container {
  background-color: white;
  width: auto;
  margin-top: 20px;
  margin-bottom: 90px;
}

@media (max-width: 1024px) {
  .benefits-c {
    order: 1;
  }

  .benefits-l {
    order: 2;
  }

  .benefits-r {
    order: 3;
    padding-bottom: 120px;
  }
}

@media only screen and (max-width: 767px) {
  .benefits-container {
    margin-top: 80px;
  }
}
