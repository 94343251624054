.start-page-title {
  padding-left: 10px;
  padding-right: 10px;
}

.start-page-title h1 {
  font-family: "Roboto";
  font-size: 28px;
  color: #000000;
  font-weight: 900;
  font-style: normal;
}

.start-page-subtitle {
  padding-top: 23px;
  font-style: normal;
}

.start-page-subtitle h2 {
  font-family: "Roboto";
  font-size: 24px;
  font-weight: 400;
  color: #333333;
  padding-left: 10px;
  padding-right: 10px;
}

.start-page-subtitle h3 {
  font-family: "Roboto";
  font-size: 18px;
  font-weight: bold;
  color: #84919c;
  padding-top: 23px;
}

@media (min-width: 1024px) {
  .start-page-title {
    padding-top: 70px;
  }

  .start-page-title h1 {
    font-size: 36px;
  }

  .start-page-subtitle h2 {
    padding-left: 264px;
    padding-right: 264px;
  }

  .start-page-subtitle h3 {
    padding-top: 96px;
  }
}

.start-page-profiles {
  width: 100%;
}

@media (min-width: 1024px) {
  .start-page-profiles {
    height: 258px;
    margin-top: 82px;
    background-color: #1b2029;
  }
}

.start-page-jamie {
  float: left;
  /*border: 1px solid red;*/
  width: 300px;
  height: 300px;
  margin-left: 20px;
  border-radius: 10px;
  background-image: url("../../../images/jamie.png");
}

.start-page-logos {
  background-color: #ffffff;
  height: 60px;
  width: 100%;
}

.start-page-logo-lifenome {
  padding-top: 10px;
  float: left;
}

.start-page-logo-wef {
  margin-left: 25px;
  padding-top: 10px;
  float: left;
}

.start-page-logo-ibm {
  margin-left: 19px;
  padding-top: 16px;
  float: left;
}

@media (min-width: 1024px) {
  .profile-card-container {
    margin-top: -62px;
  }
}
