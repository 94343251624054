.avatar-circle {
  width: 110px;
  height: 110px;
  position: fixed !important;
  bottom: 0px;
  left: 5px;
  box-shadow: 1px 2px 4px 0 var(--black-60);
  border: 50px;
}

.button-bottom {
  font-family: Roboto;
  font-size: 14px;
  color: #004d87;
  font-weight: bold;
}

.button-opacity {
  opacity: 0.5;
}

.MuiAvatar-root {
  border-style: solid !important;
}

@media only screen and (max-width:767px) {
  .avatar-circle {
    width: 90px !important;
    height: 90px !important;
    left: 35% !important;
  }
}
