.retail-experience-content {
  margin-left: 20px;
  margin-right: 20px;
}

.retail-experience-content button {
  outline: none !important;
}

.retail-experience-intro {
  margin-top: 42px;
  font-size: 18px;
  font-family: "Roboto";
  font-weight: 400;
  text-align: center;
}

.retail-experience-retailers {
  margin-top: 13px;
}

.retail-experience-product-category-title,
.retail-experience-product-brands {
  margin-top: 36px;
  font-size: 18px;
  font-family: "Roboto";
  font-weight: 400;
  text-align: center;
}

.retail-experience-product-categories {
  margin-top: 13px;
}

.focus-border {
  border: 3px solid #004d83;
}

.font-18 {
  font-size: 18px;
  font-weight: bolder;
}

.text-v-center {
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  -ms-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
}

.selected {
  border: 5px solid #004d83;
  color: #004d83;
}

.retail-experience-product-category {
  width: 140px;
  height: 105px;
  border-radius: 10px;
  position: relative;
  float: left;
  box-shadow: 1px 2px #14141406;
  margin-right: 20px;
  background-color: white;
  background-size: 140px;
}

.retail-experience-product-category-text {
  font-size: 22px;
}

.retail-experience-product-category-subtitle {
  font-size: 12px;
  color: #8b96a1;
  padding-top: 10px;
}

@media only screen and (max-width:767px) {
  .retail-experience-intro {
    margin-top: 80px;
  }
}
